import React, { Suspense } from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './assets/css/english.css';
// require('./assets/css/arabic.css');
// const BookingDetails = require('./myaccount/bookingdetails');
import { generateRoutes } from './routeUtils';
import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from './config/environment';
import ProductList from './product/productlist';
import ProductDetails from './product/productdetails';

const ContactUs = React.lazy(() => import("./common/contact"));
const NewHome = React.lazy(() => import("./home/newhome"));
const Header = React.lazy(() => import("./layout/header/Header"));
const Footer = React.lazy(() => import("./layout/footer/Footer"));
const About = React.lazy(() => import("./common/about"));
const TermsCondition = React.lazy(() => import("./common/termscondition"));
const Privacy = React.lazy(() => import("./common/privcy"));
const Faq = React.lazy(() => import("./common/faq"));
const BlogPage = React.lazy(() => import("./common/blog"));
const BlogDetails = React.lazy(() => import("./common/blogdetails"));
const Login = React.lazy(() => import("./auth/login"));
const Register = React.lazy(() => import("./auth/register"));
const Forget = React.lazy(() => import("./auth/forget"));
const MyProfile = React.lazy(() => import("./myaccount/myprofile"));
const MyDocuments = React.lazy(() => import("./myaccount/mydocuments"));
const MyBooking = React.lazy(() => import("./myaccount/mybooking"));
// const ProductList = React.lazy(() => import("./product/productlist"));
// const ProductDetails = React.lazy(() => import("./product/productdetails"));
const HotOffers = React.lazy(() => import("./hotoffers/hotoffer"));
const HotOfferDetails = React.lazy(() => import("./hotoffers/hotoffer-details"));
const PlaceList = React.lazy(() => import("./common/place-list"));
const ErrorPage = React.lazy(() => import("./common/errorpage"));
const NotificationPage = React.lazy(() => import("./auth/notification"));

initializeApp(environment.firebase);
const messaging = getMessaging();

function App() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const lanCheck = generateRoutes();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const lowercaseUrl = currentUrl.toLowerCase();

    if (currentUrl !== lowercaseUrl) {
      window.location.replace(lowercaseUrl);
    }
    requestPermission();
    listen();
  }, []);

  const requestPermission = async () => {
    try {
      const currentToken = await getToken(messaging, { vapidKey: environment.firebase.vapidKey });
      if (currentToken) {
        localStorage.setItem('deviceToken', currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.error("An error occurred while retrieving token.", err);
    }
  };

  const listen = () => {
    onMessage(messaging, (payload) => {
      setMessage(payload.notification);
    });
  };

  if (lanCheck) {
    sessionStorage.setItem('language', currentLanguage);
    sessionStorage.removeItem('clicked');
  }

  return (
    <BrowserRouter>
      <div className={`language-${lanCheck}`}>
        <Header />
        <main>
          <Routes>
            <Route path={lanCheck === 'ar' ? '/ar/contact-us' : '/en/contact-us'} element={<Suspense fallback={<p></p>}><ContactUs /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/about-us' : '/en/about-us'} element={<Suspense fallback={<p></p>}><About /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/terms-and-conditions' : '/en/terms-and-conditions'}  element={<Suspense fallback={<p></p>}><TermsCondition /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/privacy-policy' : '/en/privacy-policy'} element={<Suspense fallback={<p></p>}><Privacy /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/blog' : '/en/blog'}  element={<Suspense fallback={<p></p>}><BlogPage /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/blogdetails/:id' : '/en/blogdetails/:id'}  element={<Suspense fallback={<p></p>}><BlogDetails /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/login' : '/en/login'} element={<Suspense fallback={<p></p>}><Login /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/faq' : '/en/faq'} element={<Suspense fallback={<p></p>}><Faq /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/register' : '/en/register'} element={<Suspense fallback={<p></p>}><Register /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/forget' : '/en/forget'} element={<Suspense fallback={<p></p>}><Forget /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/myprofile' : '/en/myprofile'} element={<Suspense fallback={<p></p>}><MyProfile /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/mydoc' : '/en/mydoc'} element={<Suspense fallback={<p></p>}><MyDocuments /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/mybooking' : '/en/mybooking'} element={<Suspense fallback={<p></p>}><MyBooking /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/cheapest-car-rentals/brands/:id' : '/en/cheapest-car-rentals/brands/:id'} element={<ProductList />}/>
            <Route path={lanCheck === 'ar' ? '/ar/cheapest-car-rentals' : '/en/cheapest-car-rentals'} element={<ProductList />} />
            <Route path={lanCheck === 'ar' ? '/ar/cheapest-car-rentals/category/:id' : '/en/cheapest-car-rentals/category/:id'} element={<ProductList />} />
            <Route path={lanCheck === 'ar' ? '/ar/car-details/:id' : '/en/car-details/:id'} element={<ProductDetails />} />
            <Route path={lanCheck === 'ar' ? '/ar/special-offers' : '/en/special-offers'} element={<Suspense fallback={<p></p>}><HotOffers /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/special-offers/:id' : '/en/special-offers/:id'} element={<Suspense fallback={<p></p>}><HotOfferDetails /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/our-locations' : '/en/our-locations'} element={<Suspense fallback={<p></p>}><PlaceList /></Suspense>} />
            <Route path={lanCheck === 'ar' ? '/ar/our-locations/:id' : '/en/our-locations/:id'} element={<Suspense fallback={<p></p>}><PlaceList /></Suspense>} />
            <Route path={lanCheck === 'ar' ? 'ar/cheapest-car-rentals/location/:id' : 'en/cheapest-car-rentals/location/:id'} element={<ProductList />} />
            <Route path={lanCheck === 'ar' ? 'ar/shared/notification' : 'en/shared/notification'} element={<Suspense fallback={<p></p>}><NotificationPage /></Suspense>} />
            <Route path="/" exact element={<Navigate to={lanCheck === 'ar' ? '/ar' : '/en'} />} />
            <Route path="/en" exact element={<Suspense fallback={<p></p>}><NewHome /></Suspense>} />
            <Route path="/ar" exact element={<Suspense fallback={<p></p>}><NewHome /></Suspense>} />
            <Route path="*" element={<Suspense fallback={<p></p>}><ErrorPage /></Suspense>} />
            
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

// const _App = App;
// export { _App as App };
